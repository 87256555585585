<template>
    <div class="ship-info-container">
        <div class="search-section">
            <div class="search-item">
                <span class="search-title">船舶名称：</span>
                <Input v-model="value" placeholder="请输入船舶名称" style="width: 150px"></Input>
            </div>
            <Button style="margin-left: 20px" type="success" class="btn">搜索</Button>
        </div>
        <Table :loading="loading" :data="shipList" :columns="columns"></Table>
        <Page @on-change="pageChange" @on-page-size-change="pageSizeChange" :total="total" size="small"
            style="margin-top: 20px" show-total show-elevator show-sizer></Page>
        <div v-if="detailModal" class="mask">
            <div class="detail-content">
                <div class="detail-header">
                    <p class="detail-header-title">通行记录</p>
                </div>
                <div class="video-section">这里是视频</div>
                <div class="img-list">
                    <div class="img-list-item">图片1</div>
                    <div class="img-list-item">图片2</div>
                    <div class="img-list-item">图片3</div>
                </div>
                <div class="btn-section">
                    <Button @click="closeModalHandler" style="margin-left: 20px" type="primary" class="btn">关闭</Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            detailModal: false,
            shipList: [
                {
                    num: 1,
                    code: "SHN147236355",
                    name: "船只1",
                    ton: "1500吨",
                    createTime: "2021-12-10",
                    updateTime: "2021-12-11",
                    img: "https://img2.baidu.com/it/u=423326158,3139303466&fm=26&fmt=auto",
                },
                {
                    num: 2,
                    code: "SHN147236355",
                    name: "船只1",
                    ton: "1500吨",
                    createTime: "2021-12-10",
                    updateTime: "2021-12-11",
                    img: "https://img2.baidu.com/it/u=423326158,3139303466&fm=26&fmt=auto",
                },
                {
                    num: 3,
                    code: "SHN147236355",
                    name: "船只1",
                    ton: "1500吨",
                    createTime: "2021-12-10",
                    updateTime: "2021-12-11",
                    img: "https://img2.baidu.com/it/u=423326158,3139303466&fm=26&fmt=auto",
                },
                {
                    num: 4,
                    code: "SHN147236355",
                    name: "船只1",
                    ton: "1500吨",
                    createTime: "2021-12-10",
                    updateTime: "2021-12-11",
                    img: "https://img2.baidu.com/it/u=423326158,3139303466&fm=26&fmt=auto",
                },
            ],
            loading: false,
            total: 1233,
            columns: [
                {
                    title: "序号",
                    key: "num",
                    width: 70,
                },
                {
                    title: "编码",
                    key: "code",
                    width: 200,
                },
                {
                    title: "名称",
                    key: "name",
                    minWidth: 150,
                },
                {
                    title: "违章记录",
                    key: "img",
                    width: 200,
                    render: (h, params) => {
                        return h("div", { class: "book-div" }, [
                            h("img", {
                                attrs: {
                                    src: params.row.img,
                                },
                                style: {
                                    width: "50px",
                                    height: "50px",
                                    cursor: "pointer",
                                },
                                on: {
                                    click: () => {
                                        this.breakLawImgHandler(params.row);
                                    },
                                },
                            }),
                        ]);
                    },
                },
                {
                    title: "吨位",
                    key: "ton",
                    width: 150,
                },
                {
                    title: "创建时间",
                    key: "createTime",
                    width: 150,
                },
                {
                    title: "更新时间",
                    key: "updateTime",
                    width: 200,
                },
            ],
        };
    },
    methods: {
        pageChange() { },
        pageSizeChange() { },
        breakLawImgHandler() {
            this.detailModal = true;
        },
        ok() { },
        cancel() { },
        closeModalHandler() {
            this.detailModal = false;
        },
    },
};
</script>

<style lang="scss">
.ship-info-container {
    height: 100%;
    padding: 30px;
    text-align: center;

    .search-section {
        display: flex;
        justify-content: flex-start;
        padding-bottom: 20px;

        .search-item {
            margin-left: 20px;

            &:first-child {
                margin-left: 0;
            }
        }

        .search-title {
            font-size: 16px;
            color: #fff;
        }
    }

    .mask {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba($color: #000000, $alpha: 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;

        .detail-content {
            background-color: #fff;
            border-radius: 10px;
            padding: 0 10px;

            .detail-header {
                padding: 20px 0;

                .detail-header-title {
                    font-size: 18px;
                }
            }

            .btn-section {
                padding: 20px 0;
                display: flex;
                justify-content: flex-end;
                padding-right: 20px;
            }

            .video-section {
                width: 600px;
                height: 400px;
                background-color: red;
            }

            .img-list {
                width: 600px;
                height: 150px;
                display: flex;
                padding-top: 10px;

                .img-list-item {
                    width: 300px;
                    height: 150px;
                    background-color: red;
                    margin-left: 10px;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
</style>